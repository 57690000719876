export const ScrollToTop = (type, marginTop = 0) => {
  if (window) {
    const supportsNativeSmoothScroll =
      'scrollBehavior' in document.documentElement.style;
    if (supportsNativeSmoothScroll) {
      window.scrollTo({ top: marginTop, behavior: type });
    } else {
      window.scrollTo(marginTop, 0);
    }
  }
};
