import makeStyles from '@material-ui/core/styles/makeStyles';
import { pxToRem } from '../../../../styles/themes/vad/constants';
// import { EVENT_TYPE, EVENTS_TAG_THEME_COLOR } from '../../../../constants/index';

const useStyles = makeStyles(theme => ({
  root: {
    '&[data-locator="FormCollection"] .MuiTypography-body1': {
      fontFamily: theme.custom.fontFamily.primaryRegular,
    },
    // This comments for Event page
    // '&[data-locator="FormCollection"] .MuiButtonBase-root': {
    //   backgroundColor: ({ contentType }) => contentType === EVENT_TYPE && EVENTS_TAG_THEME_COLOR,
    // },
  },
  componentTitle: {
    marginBottom: pxToRem(24),
    [theme.breakpoints.up('lg')]: {
      marginBottom: pxToRem(48),
    },
  },
}));

export default useStyles;
