import React, { useEffect } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withNamespaces } from 'react-i18next';

import useStyles from './Select.styles';
import Typography from '../../Typography';
import { canUseDOM } from '../../../../utils/canUseDOM';

const renderMenuItems = (listItems, useMappingId) => {
  const menuItems = listItems.map((item, index) => {
    return (
      <MenuItem
        value={
          useMappingId && useMappingId.value
            ? item.fields.mappingId.value
            : item.fields.key.value || index + 1
        }
        key={item.id}
      >
        {item.fields.displayText.value}
      </MenuItem>
    );
  });
  return menuItems;
};

const renderGroupedItems = listItems => {
  let groupedmenuItemsDOM = [];

  listItems.forEach((items, index) => {
    groupedmenuItemsDOM.push(
      <MenuItem
        key={`${items.groupheading.key}_${index}`}
        value={`${items.groupheading.key}`}
      >
        {items.groupheading.value}
      </MenuItem>
    );
    items.groupItems.map((groupItem, key) =>
      groupedmenuItemsDOM.push(
        <MenuItem
          className="grouped-item"
          value={groupItem.key.value}
          key={`${groupItem.key.value}_${key}`}
        >
          {groupItem.displayText.value}
        </MenuItem>
      )
    );
  });
  return groupedmenuItemsDOM;
};

const renderText = text => (
  <Typography variant={'body2'} component={'span'} gutterBottom={false}>
    <Text field={text} />
  </Typography>
);

/*
 * This function adds grey-color class to select element when no value is selected to display placeholder in grey color
 */
const addGreyColorClassForPlaceholder = fieldName => {
  if (canUseDOM) {
    const inputElement = document.querySelector(
      `input[name='${fieldName.value}']`
    );
    const dropdownElement = document.querySelector(
      `#select_dropdown_${fieldName.value}`
    );
    if (inputElement && dropdownElement) {
      if (!inputElement.value) {
        dropdownElement.classList.add('grey-text');
      } else {
        dropdownElement.classList.remove('grey-text');
      }
    }
  }
};

const SelectMUI = props => {
  const classes = useStyles();
  // if isGroupedDropdown is true then dropdown options will be grouped while rendering
  const { isGroupedDropdown, t: dictionary, labelVariant = 'body2Bold', } = props;
  const {
    fieldLabel,
    selectedValue,
    fieldName,
    listItems,
    showError,
    validationMessage,
    isDisabled,
    placeHolder,
    isRequired,
    useMappingId,
  } = props.data;

  // Use optional chaining (?.) to access properties safely
  const selectedValueValue = selectedValue?.value;
  const isDisabledValue = isDisabled?.value;
  const isRequiredValue = isRequired?.value;
  const placeHolderValue = placeHolder?.value;
  const useMappingIdValue = useMappingId?.value;

  const placeholderSuffix =
    dictionary &&
    dictionary('selectOption') !== 'selectOption' &&
    dictionary('selectOption');
  const menuProps = {
    variant: 'menu',
    disablePortal: true,
    disableScrollLock: true,
    autoFocus: true,
  };

  useEffect(() => {
    addGreyColorClassForPlaceholder(fieldName);
  });

  return (
    <FormControl
      component="fieldset"
      className={`${classes.formControl} ${props.className}`}
      aria-labelledby={`select_label_${fieldName.value}`}
    >
      <legend />
      {fieldLabel && (
        <InputLabel
          id={`select_label_${fieldName.value}`}
          className={classes.inputLabel}
          // htmlFor={`select_dropdown_input_${fieldName.value}`}
          shrink={true}
        >
          <Typography
            variant={labelVariant}
            component={'span'}
            gutterBottom={false}
          >
            <Text field={fieldLabel} />
          </Typography>
          {isRequired && isRequired.value && <span> *</span>}
        </InputLabel>
      )}
      <Select
        labelId={`select_label_${fieldName.value}`}
        id={`select_dropdown_${fieldName.value}`}
        value={selectedValueValue}
        name={fieldName.value}
        className={`${classes.selectInput} align-with-input`}
        onChange={props.onChange}
        inputProps={{ id: `select_dropdown_input_${fieldName.value}` }}
        MenuProps={menuProps}
        displayEmpty
        disabled={Boolean(isDisabledValue)}
        IconComponent={'span'}
      >
        <MenuItem value="" disabled>
          {placeHolderValue
            ? placeHolderValue
            : `${placeholderSuffix ? placeholderSuffix : ''}`}
        </MenuItem>
        {isGroupedDropdown
          ? renderGroupedItems(listItems)
          : renderMenuItems(listItems, useMappingIdValue)}
      </Select>
      {showError && (
        <div className={classes.errorMsg}>
          {renderText(
            validationMessage &&
            validationMessage.fields &&
            validationMessage.fields.displayText
          )}
        </div>
      )}
    </FormControl>
  );
};

export default withNamespaces()(SelectMUI);
