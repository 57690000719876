import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from '../../../../styles/themes/vad/borderRadius';
import { pxToRem } from '../../../../styles/themes/vad/constants';
import { fontSize } from '../../../../styles/themes/vad/font';

const useStyles = makeStyles(theme => {
  const { left, right, paddingLR } = theme.mixins;
  return {
    root: {
      '& .MuiAutocomplete-root': {
        width: '100% !important',
      },
      '& .MuiFormLabel-root': {
        fontSize: fontSize.s16Rem,
        lineHeight: fontSize.s24Rem,
        fontFamily: theme.custom.fontFamily.primaryBold,
        top: pxToRem(33),
        ...left(pxToRem(16)),
        zIndex: 1,

        '&.MuiInputLabel-shrink': {
          ...left(0),
          ...right('auto'),
          transform: 'translate(0, -14.5px) scale(0.9)',
        },
      },
      '& .searchBarInputStyle': {
        '& input': {
          fontFamily: theme.custom.fontFamily.primaryRegular,
        },
      },
      '& .MuiInputBase-root': {
        height: pxToRem(48),
        background: theme.mixins.White(),
        marginTop: pxToRem(46),
        border: theme.borders.fieldBorder,
        borderRadius: borderRadius.b3,
        paddingRight: theme.spacing(4.5),
        [theme.breakpoints.up('lg')]: {
          lineHeight: fontSize.s32Rem,
        },
        '&.MuiInput-underline:before': {
          display: 'none',
        },
        '&.MuiInput-underline:after': {
          display: 'none',
        },
        '&:hover, &:focus': {
          backgroundColor: theme.palette.action.disabledBackground,
        },

        '& input': {
          '&::-ms-clear': {
            display: 'none',
            height: 0,
            width: 0,
          },
          '&::placeholder': {
            color: theme.palette.text.tertiary,
          },
          '&::-webkit-input-placeholder': {
            /* Chrome/Opera/Safari */
            color: theme.palette.text.tertiary,
            opacity: 1,
          },
          '&::-moz-placeholder': {
            color: theme.palette.text.tertiary,
            opacity: 1,
          },
          '&:-ms-input-placeholder': {
            /* IE 10+ */
            color: theme.palette.text.tertiary,
            opacity: 1,
          },
          '&:-moz-placeholder': {
            /* Firefox 18- */
            color: theme.palette.text.tertiary,
            opacity: 1,
          },
        },
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
        fontSize: fontSize.s16Rem,
        lineHeight: fontSize.s24Rem,
        paddingLeft: pxToRem(16),
      },
      '& .MuiAutocomplete-endAdornment': {
        ...right(0),
        ...left('auto'),
      },
      '& .MuiIconButton-root': {
        '&:hover': {
          background: 'inherit',
        },
        '&.MuiAutocomplete-clearIndicator .MuiIconButton-label': {
          position: 'absolute',
          top: pxToRem(-9),
          ...right(pxToRem(50)),
          color: theme.mixins.Black(),
        },
        '&.MuiAutocomplete-popupIndicator': {
          '&.MuiAutocomplete-popupIndicatorOpen': {
            transform: 'none',

            '& .MuiIconButton-label:after': {
              transform: 'rotate(225deg)',
              top: '-5px',
            },
          },
          '& .MuiIconButton-label': {
            position: 'relative',
            color: theme.mixins.Black(),
            '&:after': {
              content: "''",
              position: 'absolute',
              border: 'solid',
              width: pxToRem(10),
              height: pxToRem(10),
              borderWidth: '0 2px 2px 0',
              transform: 'rotate(45deg)',
              ...right(pxToRem(22)),
              [theme.breakpoints.up('lg')]: {
                top: pxToRem(-11),
              },
            },
            '& .MuiSvgIcon-root': {
              display: 'none',
            },
          },
        },
      },
    },
    errorMsg: {
      color: theme.palette.text.error,
      marginTop: 8,

      '& .MuiTypography-body2': {
        fontSize: fontSize.s14Rem,
      },
    },
    '@global': {
      '.MuiAutocomplete-popper': {
        borderRadius: borderRadius.b0,

        '& .MuiAutocomplete-paper': {
          margin: 0,

          '& .MuiAutocomplete-noOptions': {
            display: 'none',
          },
          '& .MuiAutocomplete-listbox': {
            padding: 0,
            height: pxToRem(169),
            border: theme.borders.dropDownBorder,
            overflowY: 'auto',

            '& li': {
              position: 'relative',
              color: theme.mixins.Black(),
              '&:not(:last-child):after': {
                content: "''",
                position: 'absolute',
                width: '90%',
                bottom: 0,
                borderBottom: theme.borders.divider,
              },
            },
            '& .MuiAutocomplete-option': {
              fontSize: fontSize.s16Rem,
              paddingTop: pxToRem(11),
              paddingBottom: pxToRem(11),
              ...paddingLR(pxToRem(16), pxToRem(16)),
            },
          },
        },
      },
    },
  };
});

export default useStyles;
