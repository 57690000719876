import React from 'react';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import TextFieldMUI from '../TextField';
import useStyles from './AutoComplete.style';
import Typography from '../Typography';

const AutoCompleteMUI = props => {
  const classes = useStyles();
  const {
    inputChange,
    listItems,
    disableClearable,
    inputValue,
    onKeyDown,
    isFreeSolo,
    isSearchBar,
    variant = 'body3',
    data,
    classNameInput,
  } = props;
  const {
    fieldName,
    selectedValue,
    fieldLabel,
    showError,
    validationMessage,
    placeHolder,
  } = data || {};

  const renderText = text => (
    <Typography variant={variant} component={'span'} gutterBottom={false}>
      <Text field={text} />
    </Typography>
  );

  const onInputChange = (params, value) => {
    inputChange(value);
  };

  const onChange = (event, value, props) => {
    let target = {};
    target.name = fieldName.value;
    if (value) {
      target.value = value.name;
      props.onChange({ target: target });
    }
  };

  const filter = createFilterOptions();
  const filterOptions = (options, params) => {
    let filteredResults = filter(options, params);
    // This code returns the original listItems in case of search bar
    if (params.inputValue !== '') {
      if (isSearchBar) {
        filteredResults = listItems;
      }
    }

    return filteredResults;
  };

  return (
    <FormControl
      component="fieldset"
      aria-label={fieldLabel.value}
      className={classes.root}
    >
      <legend />
      <Autocomplete
        id={fieldName.value}
        options={listItems}
        name={fieldName.value}
        getOptionLabel={option => option.name}
        noOptionsText={''}
        filterOptions={filterOptions}
        style={{ width: 300 }}
        inputValue={inputValue}
        onChange={(event, value) => onChange(event, value, props)}
        aria-labelledby={`${fieldName.value}-label`}
        renderOption={option => (
          <React.Fragment>{renderText({ value: option.name })}</React.Fragment>
        )}
        renderInput={params => (
          <TextFieldMUI
            {...params}
            value={selectedValue && selectedValue.value}
            label={<Text field={fieldLabel} />}
            onKeyDown={onKeyDown}
            placeholder={placeHolder && placeHolder.value}
          />
        )}
        clearOnBlur={false}
        onInputChange={(params, value) => onInputChange(params, value)}
        disablePortal
        freeSolo={isFreeSolo ? true : false}
        disableClearable={disableClearable}
        className={`text-field ${classNameInput}`}
      />
      {showError && (
        <div className={classes.errorMsg}>
          {renderText(
            validationMessage &&
            validationMessage.fields &&
            validationMessage.fields.displayText
          )}
        </div>
      )}
    </FormControl>
  );
};

export default AutoCompleteMUI;
