import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from '../../../../../styles/themes/vad/borderRadius';
import { pxToRem } from '../../../../../styles/themes/vad/constants';
import { fontSize } from '../../../../../styles/themes/vad/font';

const useStyles = makeStyles(theme => {
  const { left, right, paddingLR } = theme.mixins;

  return {
    formControl: {
      margin: 0,
      padding: 0,
      width: pxToRem(306),
    },
    inputLabel: {
      zIndex: 1,
      ...left(pxToRem(16)),
      top: pxToRem(27),
      '&.MuiInputLabel-shrink': {
        transform: `translate(0, ${pxToRem(-14.5)}) scale(0.9)`,
        ...left(0),
        ...right('auto'),
      },
      '& + .MuiInput-formControl': {
        marginTop: pxToRem(46),

        '& .MuiSelect-iconOpen.MuiSelect-icon:after': {
          ...right(pxToRem(-31)),
          top: pxToRem(4),
        },
        '& .MuiSelect-icon': {
          position: 'relative',
          top: `calc(50% - ${pxToRem(12)})`,

          '&:after': {
            content: "''",
            position: 'absolute',
            border: `solid ${theme.mixins.Black()}`,
            width: pxToRem(10),
            height: pxToRem(10),
            borderWidth: '0 2px 2px 0',
            transform: 'rotate(45deg)',
            top: pxToRem(-18),
            ...right(pxToRem(22)),
          },
        },
      },
    },
    selectInput: {
      height: pxToRem(48),
      background: theme.mixins.White(),
      border: theme.borders.fieldBorder,
      borderRadius: borderRadius.b3,

      '&.align-with-input': {
        '& > .MuiPopover-root': {
          // these important properties are required to override
          // inline styling
          position: 'absolute !important',
          '& .MuiPaper-root': {
            left: '0 !important',
            top: '0 !important',
          },
        },
      },

      '& .MuiPaper-root': {
        marginTop: pxToRem(48),
        width: 'auto',
        border: theme.borders.dropDownBorder,
        maxHeight: pxToRem(169),
        borderRadius: borderRadius.b0,

        '& .MuiMenu-list': {
          padding: 0,
          margin: 0,

          '& .MuiListSubheader-sticky': {
            position: 'relative',
            color: theme.mixins.Black(600),
            background: theme.mixins.Black(20),
            pointerEvents: 'none',
            fontSize: fontSize.s16Rem,

            '&:after': {
              ...left(pxToRem(16)),
            },
          },
          '& li:not(:last-child):after': {
            content: "''",
            position: 'absolute',
            ...left(pxToRem(16)),
            width: '90%',
            bottom: 0,
            borderBottom: theme.borders.divider,
          },
          '& .MuiMenuItem-root': {
            ...theme.typography.body3,
            paddingTop: pxToRem(11),
            paddingLeft: pxToRem(16),
            paddingBottom: pxToRem(11),

            '&.grouped-item': {
              ...paddingLR(pxToRem(32), 0),

              '&:after': {
                ...left(pxToRem(16)),
              },
            },
          },
        },
      },
      '& .MuiSelect-select': {
        ...theme.typography.body2,
        paddingTop: pxToRem(11),
        paddingLeft: pxToRem(16),
        paddingBottom: pxToRem(11),

        '&:hover, &:focus': {
          background: theme.palette.action.disabledBackground,
        },
      },
      '&.MuiInput-underline.Mui-focused:after': {
        display: 'none',
      },
      '&.MuiInput-underline:before': {
        display: 'none',
      },
    },
    menuItems: {
      display: 'block',
      '& .MuiMenuItem-root': {
        ...theme.typography.body3,
      },
      '& li:not(:last-child)': {
        borderBottom: theme.borders.divider,
      },
      '& li': {
        width: pxToRem(306),
        whiteSpace: 'pre-wrap',
        paddingTop: pxToRem(16),
        paddingBottom: pxToRem(16),
      },
    },
    errorMsg: {
      color: theme.palette.text.error,
      marginTop: pxToRem(8),
    },
  };
});

export default useStyles;
