import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Divider from '@material-ui/core/Divider';

import Box from '../../../../../components/molecules/Box';
import Typography from '../../../../../components/atoms/Typography';

import useStyles from '../../style';
import FormCollection from '../../../FormCollection';
import { NEWSLETTER } from '../../../../../components/organism/FormBuilder/constants';
const Newsletter = ({
  isMobile,
  subscribeTitle,
  description,
  ctaText,
  newsletterForm,
}) => {
  const classes = useStyles();
  const updateNewsletterFields = newsletterForm && Array.isArray(newsletterForm.fields.fields) && newsletterForm.fields.fields.map(item => {
    if (item.fields.fieldName?.value === 'country') {
      return {
        ...item,
        fields: {
          ...item.fields,
          fieldType: { value: 'AutoComplete' },
          selectedValue: { value: '' },
          placeHolder: { value: 'Start typing here' },
        },
      };
    } else {
      return item;
    }
  });

  const formFields = {
    formItemList: [{
      fields: {
        form: {
          // fields: newsletterForm && newsletterForm.fields,
          fields: newsletterForm && {
            ...newsletterForm.fields, fields: updateNewsletterFields,
          },
          formTitle: newsletterForm && newsletterForm.fields && newsletterForm.fields.formTitle,
        },
        linkText: ctaText,
      },
    }],
    stripColor: newsletterForm && newsletterForm.fields && newsletterForm.fields.stripColor,
  };

  return (
    <>
      <Box component="div" className={classes.newsletter}>
        <Typography
          component="h2"
          className={classes.heading}
          variant="h5Bold"
          animateSettings={{
            willAnimate: false,
          }}
        >
          {subscribeTitle && <Text field={subscribeTitle} />}
        </Typography>
        <Typography
          component="p"
          variant="body2Light"
          className={classes.subscribeDescription}
        >
          {description && <Text field={description} />}
        </Typography>
        {newsletterForm && newsletterForm.fields && ctaText && (
          <FormCollection
            fields={formFields}
            popoverPosition="bottom"
            hasBtn
            formType={NEWSLETTER}
          />
        )}
      </Box>
      {isMobile && (
        <Box component="div" className={classes.divider}>
          <Divider component="div" />
        </Box>
      )}
    </>
  );
};

export default Newsletter;
