import { canUseDOM } from './canUseDOM';
import { isIE } from './isIEBrowser';

const scrollTo = position => {
  if (canUseDOM) {
    if (isIE) {
      window.scrollTo(0, position);
    } else {
      window.scrollTo({ top: position, behavior: 'smooth' });
    }
  }
};

export default scrollTo;
