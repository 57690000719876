const validate = (value, rules) => {
  let isValid = true;

  for (let rule of rules) {
    switch (rule) {
      case 'minLength':
        isValid = isValid && minLengthValidator(value, rules[rule]);
        break;

      case 'isRequired':
        isValid = isValid && requiredValidator(value);
        break;

      case 'isEmail':
        isValid = isValid && emailValidator(value);
        break;

      case 'isPassword':
        isValid = isValid && passwordValidator(value);
        break;

      default:
        isValid = true;
    }
  }

  return isValid;
};

/**
 * minLength Val
 * @param  value
 * @param  minLength
 * @return
 */
const minLengthValidator = (value, minLength) => {
  return value.length >= minLength;
};

/**
 * Check to confirm that field is required
 *
 * @param  value
 * @return
 */
const requiredValidator = value => {
  return value !== '';
};

/**
 * Email validation
 *
 * @param value
 * @return
 */
export const emailValidator = value => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
};

/**
 * Password validation
 *
 * @param value
 * @return
 */
const passwordValidator = value => {
  var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,10}$/;
  if (value.match(re)) {
    return true;
  }
  return false;
};

export default validate;
