import makeStyles from '@material-ui/core/styles/makeStyles';
import { borderRadius } from '../../../../styles/themes/vad/borderRadius';
import { pxToRem } from '../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiBox-root': {
      transform: 'none',
      marginBottom: pxToRem(24),
      [theme.breakpoints.up('lg')]: {
        marginBottom: pxToRem(34),
      },
    },
    '& .MuiAvatar-colorDefault': {
      color: theme.mixins.Black(),
      transform: theme.mixins.rotate0(),
      '& svg': {
        height: pxToRem(14),
        [theme.breakpoints.up('lg')]: {
          height: 'inherit',
        },
      },
    },
  },
  popoverWrapper: {
    position: 'relative',
    background: theme.mixins.White(),
    [theme.breakpoints.down('md')]: {
      overflow: 'auto',
      height: '100vh',
    },
    [theme.breakpoints.up('lg')]: {
      boxShadow: `0px 0px 9px -5px ${theme.mixins.Black(500)}`, // '0px 0px 9px -5px rgba(0, 0, 0, 0.5)',

      borderRadius: borderRadius.b0,
    },
    '& .close-btn': {
      position: 'absolute',
      border: `1px solid ${theme.mixins.Black()}`,
      zIndex: 1,
      width: pxToRem(45),
      height: pxToRem(45),
      top: pxToRem(33),
      padding: 0,
      ...theme.mixins.right(pxToRem(25)),
      [theme.breakpoints.up('lg')]: {
        top: pxToRem(38),
        ...theme.mixins.right(pxToRem(72)),
      },
    },
  },

  popoverRoot: {
    zIndex: theme.zIndex.drawer,
    top: '0 !important',
    left: '0 !important',
    height: '100vh',
    position: 'fixed !important',
    transform: 'none !important',
    [theme.breakpoints.down('md')]: {
      overflow: 'auto',
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      position: 'absolute !important',
      ...theme.mixins.right('0 !important'),
      borderRadius: borderRadius.b0,
      width: '60%',
      // 156 is header height plus margin between
      // popover and header
      top: '156px !important',
      height: 'auto',
      ...theme.mixins.left('auto !important'),

      '&.hasNotificationbar': {
        // if we have notification bar then we have to
        // compensate it's position from top to prevent contact us
        // form overlapping with header
        top: '244px !important',
      },

      '&.bottom': {
        top: 'auto !important',
        width: '55%',
        [theme.breakpoints.up('xxl')]: {
          width: '45%',
        },
      },
    },
  },
  scrollLock: {
    overflow: 'hidden',
  },
}));

export default useStyles;
