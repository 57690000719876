import React, { useEffect } from 'react';
import Popper from '@material-ui/core/Popper';
import clsx from 'clsx';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Slide from '@material-ui/core/Slide';
import { get } from 'lodash';
import Button from '../Button';
import useStyles from './style';
import DctSvgIcon from '../Svg';
import CustomLink from '../Link';
import Typography from '../Typography';
import IconWithText from '../../molecules/IconWithText';
import { canUseDOM } from '../../../utils/canUseDOM';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { analyticsData } from '../../../../constants/Analytics/analytics-data';
// import { useTheme } from '@material-ui/core/styles';
import { breakpoints } from '../../../../styles/themes/vad/constants';
import { createDynamicLabels } from '../../../../utils/analytics';
import { LANGUAGE_CODE } from '../../../../constants';
import cookie from '../../../utils/cookie';
import scrollTo from '../../../utils/scrollTo';

const Popover = ({
  textField,
  className,
  children,
  position,
  hasBtn,
  closeIconAriaLabel,
  pageName,
  componentName,
  sitecoreContext,
  ...other
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [footerHeight, setFooterHeight] = React.useState(null);
  // const theme = useTheme();
  // const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isDesktop = useMediaQuery(`(min-width:${breakpoints.values.lg}px)`);
  const shouldSetBottomPosition =
    canUseDOM && position === 'bottom' && isDesktop;
  const handleClick = event => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    if (shouldSetBottomPosition) {
      const footerHeight =
        shouldSetBottomPosition && document.querySelector('footer');
      setFooterHeight(footerHeight.offsetHeight);
      setTimeout(() => {
        // 180px is the combination of header height and the margin
        // compensation between header and newsletter poppor
        const popoverElm = document.querySelector('.bottom').offsetTop - 180;
        scrollTo(popoverElm);
      }, 800);
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  let popperProps = {};
  if (position === 'bottom') {
    popperProps = {
      ...popperProps,
      placement: 'bottom-start',
    };
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (canUseDOM && !isDesktop) {
      const bodyElm = document.querySelector('body');
      if (anchorEl) {
        bodyElm.classList.add(classes.scrollLock);
      } else {
        bodyElm.classList.remove(classes.scrollLock);
      }
    }
  }, [anchorEl]);

  useEffect(() => {
    setTimeout(() => {
      const focusableField = document.querySelector('.form-wrapper.focusOnFirstInput input');
      if (focusableField) {
        focusableField.focus();
      }
    }, 500);
  }, [open]);

  React.useLayoutEffect(() => {
    function updateSize() {
      const footer =
        shouldSetBottomPosition && document.querySelector('footer');
      setFooterHeight(footer.offsetHeight);
    }
    canUseDOM && window.addEventListener('resize', updateSize);
    updateSize();
    return () => canUseDOM && window.removeEventListener('resize', updateSize);
  }, [footerHeight]);

  return (
    <div className={clsx(classes.root, className)}>
      {hasBtn ? (
        <Button
          type="button"
          buttonType="form"
          size="small"
          hasBorder={false}
          onClick={handleClick}
        >
          <Typography
            variant="body2Bold"
            component="span"
            color="inherit"
            className="primaryCTA"
          >
            <Text field={textField} />
          </Typography>
        </Button>
      ) : (
        <CustomLink
          data-id={get(analyticsData, 'dataIds.pageBodyNavLinkClick', '')}
          data-link={createDynamicLabels([pageName, componentName, textField && textField.value])}
          onClick={handleClick}
          link={{
            value: { href: '', title: '', target: '', class: '' },
          }}
        >
          <IconWithText
            isTextThenIcon
            iconWithCircle={false}
            iconType="ArrowForwardIosIcon"
          >
            <Typography variant="body1" component="span">
              <Text field={textField} />
            </Typography>
          </IconWithText>
        </CustomLink>
      )}

      <Popper
        id={id}
        className={clsx(
          classes.popoverRoot,
          position,
          { 'hasNotificationbar': Number(cookie.read('notificationBar')) },
          'popoverContainer'
        )}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        {...popperProps}
        {...other}
        style={{ bottom: footerHeight }}
      >
        <Slide
          direction={
            get(sitecoreContext, 'language', '').toLowerCase() === LANGUAGE_CODE.ARABIC.toLowerCase() ||
              get(sitecoreContext, 'language', '').toLowerCase() === LANGUAGE_CODE.HEBREW.toLowerCase()
              ? 'right'
              : 'left'
          }
          in={open}
          mountOnEnter
          unmountOnExit
          timeout={600}
        >
          <div className={classes.popoverWrapper} role="dialog">
            <Button
              onClick={handleClose}
              iconOnly
              color="inherit"
              className="close-btn"
              srText={closeIconAriaLabel}
            >
              <DctSvgIcon name="CloseIcon" />
            </Button>
            {children}
          </div>
        </Slide>
      </Popper>
    </div>
  );
};

export default withSitecoreContext()(Popover);
