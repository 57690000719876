import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { withNamespaces } from 'react-i18next';
import clsx from 'clsx';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { breakpoints } from '../../../../styles/themes/vad/constants';
import { Select, CheckBox, InputField } from '../../../components/atoms/Forms/';
import withErrorBoundary from '../../../utils/HOCs/withErrorBoundary';
import useStyles from './styles';
import ButtonMUI from '../../atoms/Button';
import RichText from '../../atoms/RichText';
import Title from '../../atoms/Title';
import Typography from '../../atoms/Typography';
import DctSvgIcon from '../../atoms/Svg';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import isEmpty from 'lodash/isEmpty';
import fetchService from '../../../../services/FetchService';
import getAppConfig from '../../../utils/getAppConfig';
import { get } from 'lodash';
import { emailValidator } from '../../../utils/validationRules';
import formFieldTypes, { NEWSLETTER, requestTypeMapping, COMMON_FORM } from './constants';
import { canUseDOM } from '../../../utils/canUseDOM';
import { pushAnaylyticsData, createDynamicLabels } from '../../../../utils/analytics';
import { getDomainfromEmail } from '../../../../utils/utility';
import { analyticsData } from '../../../../constants/Analytics/analytics-data';
import { getDictionaryText } from '../../../utils/getDictionaryText';
import scrollTo from '../../../utils/scrollTo';
import Grid from '../../../components/molecules/Grid';
import AutoCompleteMUI from '../../../components/atoms/AutoComplete';


const apiUrl = getAppConfig();

const {
  SINGLELINETEXT,
  EMAIL,
  PHONE,
  MULTILINETEXT,
  DROPDOWN,
  CHECKBOX,
  CHECKBOXLIST,
  CAPTCHA,
  SUBMIT,
  HIDDEN,
  AUTOCOMPLETE,
} = formFieldTypes;

const FormBuild = props => {
  const {
    formFields,
    requestType,
    language,
    formType,
    emailSubject,
    stripColor,
    pageName,
    componentName,
    hasPopover,
    className,
  } = props;
  const captchaApiKey = getDictionaryText('googleCaptchaApiKey');
  const classes = useStyles({ ...stripColor, hasPopover });
  const [fieldName, setFieldName] = React.useState({});
  const [errors, setFieldError] = React.useState({});
  const [recaptchachecked, setRecaptchachecked] = React.useState(false);
  const [isFormValid, setFormIsValid] = React.useState(false);
  const [status, setStatus] = React.useState(false);
  const [submissionStatus, setSubmissionStatus] = React.useState(false);
  const [defaultSelectedListItem, setDefaultSelectedListItem] = React.useState(
    undefined
  );
  const [searchTerm, setSearchTerm] = useState({ value: '', lang: '' });
  const [searchListItems, setSearchListItems] = useState([]);

  const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(null);
    React.useEffect(() => {
      if (isEmpty(value) && searchListItems.length > 0) {
        setAutoCompleteListItems(searchListItems)
      }
      const timer = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(timer);
      };
    }, [value, delay]);
    return debouncedValue;
  };

  const debouncedSearchTerm = useDebounce(searchTerm.value, 500)
  const isDesktop = useMediaQuery(`(min-width:${breakpoints.values.lg}px)`);
  const [autoCompleteListItems, setAutoCompleteListItems] = useState([{ name: '' }])
  React.useEffect(() => {
    shouldValidateCaptcha();
    setFormIsValid(inValidFields.length === 0);
  });

  React.useEffect(() => {
    if (canUseDOM && (isDesktop || !hasPopover) && submissionStatus) {
      setTimeout(() => {
        let popoverElm = document.querySelector('.popoverContainer');
        if (!hasPopover) {
          popoverElm = document.querySelector('.messageWrapper');
        }
        if (popoverElm) {
          const popoverElmOffset = popoverElm && popoverElm.offsetTop - 180;
          scrollTo(popoverElmOffset);
        }
      }, 500);
    }
  }, [submissionStatus]);

  React.useEffect(() => {
    setCheckboxListDefaultValue();
    autocompleteAPIForTravelPayout('', language);
  }, []);

  React.useEffect(() => {
    if (debouncedSearchTerm) {
      autocompleteAPIForTravelPayout(debouncedSearchTerm, searchTerm.lang);
    }
  }, [debouncedSearchTerm]);

  if (isEmpty(formFields)) {
    return null;
  }

  const autocompleteAPIForTravelPayout = (value, lang) => {
    if (searchListItems.length > 0 && !isEmpty(value)) {
      const filterResult = searchListItems.filter(o => o.name.toLowerCase().includes(value.toLowerCase()));
      setAutoCompleteListItems(filterResult)
      return
    }
    try {
      const requestData = {
        url: `${apiUrl.AUTO_COMPLETE_TRAVEL_PAYOUT}?term=${value}&locale=${lang}&types[]=country,city,airport`,
        method: 'Get',
      };
      fetchService(requestData).then(res => {
        if (get(res, 'data', '')) {
          let options = [];
          const apiData = res.data;
          apiData &&
            apiData.forEach(value => {
              if (value.country_name) {
                options.push({ name: `${value.name ? value.name : ''}${value.city_name ? `, ${value.city_name}` : ''}${value.country_name ? `, ${value.country_name}` : ''}${value.code ? ` (${value.code})` : ''}` });
              }
            });
          if (options.length === 0) return
          setAutoCompleteListItems(options)
        }
      });
    } catch (err) {
      console.error('flights autocomplete API failure', err);
    }
  }

  const onInputChange = value => {
    setSearchTerm({ value: value, lang: language })
  };

  const handleChange = (e, fieldType) => {
    setFieldName({
      ...fieldName,
      [e.target.name]: e.target.value,
    });
    if (fieldType === formFieldTypes.EMAIL) {
      if (
        fieldName[e.target.name] !== e.target.value &&
        !emailValidator(e.target.value)
      ) {
        return setFieldError({
          ...errors,
          invalidEmail: true,
        });
      }
      return setFieldError({
        ...errors,
        invalidEmail: false,
      });
    }
  };

  const requiredFields = formFields.fields.filter(
    ({ fields }) => fields.isRequired && fields.isRequired.value
  );
  const inValidFields = requiredFields
    .map(({ fields }) => {
      if (
        !fieldName[fields.fieldName.value] ||
        fieldName[fields.fieldName.value].length === 0
      ) {
        if (
          fields.fieldType.value !== CHECKBOXLIST &&
          fields.defaultValue &&
          fields.defaultValue.fields.displayText.value
        ) {
          return setFieldName({
            ...fieldName,
            [fields.fieldName.value]:
              fields.defaultValue.fields.displayText.value,
          });
        }
        return false;
      }
      if (errors['invalidEmail']) {
        return false;
      }
      return recaptchachecked;
    })
    .filter(value => !value);

  const setCheckboxListDefaultValue = () => {
    return requiredFields.map(({ fields }) => {
      if (fields.fieldType.value === CHECKBOXLIST) {
        if (!hasPopover &&
          fields.defaultMultiValue && fields.defaultMultiValue.some(s => s.fields.key.value)
        ) {
          const defaultSelectedItems = fields.defaultMultiValue.map(item => item.fields.key.value);
          setDefaultSelectedListItem(defaultSelectedItems);
          return setFieldName({
            ...fieldName,
            [fields.fieldName.value]: defaultSelectedItems,
          });
        } else if (
          fields.defaultValue &&
          fields.defaultValue.fields.displayText.value
        ) {
          setDefaultSelectedListItem([fields.defaultValue.fields.key.value]);
          return setFieldName({
            ...fieldName,
            [fields.fieldName.value]: [fields.defaultValue.fields.key.value],
          });
        }
      }
      return false;
    });
  };

  const handleValidation = () => {
    let fieldError = {};
    let formIsValid = false;

    requiredFields.forEach(({ fields }) => {
      if (
        !fieldName[fields.fieldName.value] ||
        fieldName[fields.fieldName.value].length === 0
      ) {
        fieldError[fields.fieldName.value] = true;
        formIsValid = false;
      }
      if (errors['invalidEmail']) {
        formIsValid = false;
      } else {
        formIsValid = true;
        fieldError[fields.fieldName.value] = false;
      }
    });
    setFieldError({
      ...errors,
      ...fieldError,
    });
    setFormIsValid(formIsValid);
    return formIsValid;
  };

  const recaptchaCallback = value => {
    //If value is long string then we have sucessfully submited
    // google captcha and now we can submit the form
    setRecaptchachecked(value.length > 0);
  };
  const shouldValidateCaptcha = () => {
    const hasCaptcha = formFields.fields.some(
      ({ fields }) => fields.enableCaptcha && fields.enableCaptcha.value
    );

    if (!hasCaptcha) {
      setRecaptchachecked(true);
    }
    return hasCaptcha;
  };

  const lists = [];
  const setMultipleCheckbox = e => {
    const index =
      fieldName[e.target.name] &&
      fieldName[e.target.name].indexOf(e.target.value);
    if (index === -1) {
      lists.push(e.target.value);
    } else {
      fieldName[e.target.name] && fieldName[e.target.name].splice(index, 1);
    }
    setFieldName({
      ...fieldName,
      [e.target.name]: [...fieldName[e.target.name], ...lists],
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (handleValidation() && recaptchachecked) {
      let data = {
        firstName: fieldName.first_name,
        lastName: fieldName.last_name,
        email: fieldName.email_address,
        phone: fieldName.phone_number,
        country: fieldName.country,
        city: fieldName.city,
        subject: fieldName.subject,
        message: fieldName.message,
        type: requestTypeMapping[requestType],
        emailConsent: fieldName.receive_email_updates,
        emailSubject: `${getDictionaryText(
          'emailSubjectText'
        )} ${emailSubject}`,
        language: language,
        list: fieldName.lists || defaultSelectedListItem,
      };

      let formSubmissionUrl = apiUrl.FORM_SUBMISSION_API;
      if (formType === NEWSLETTER) {
        const dynamicLabel = data && `${get(data, 'country', '').toLowerCase()}-${getDomainfromEmail(data.email)}`;
        const obj = {
          ...analyticsData.footerInteraction.subscribeEmail,
          ...{
            label: createDynamicLabels([pageName, componentName, dynamicLabel]),
          },
        };
        pushAnaylyticsData(obj);
        formSubmissionUrl = apiUrl.NEWSLETTER_SUBSCRIPTION_API;
        // we don't need type property for newsletter API
        delete data['emailSubject'];
      } else {
        const dynamicLabel = `${get(data, 'country', '').toLowerCase()}-${get(
          data,
          'city',
          ''
        ).toLowerCase()}-${getDomainfromEmail(data.email).toLowerCase()}`;
        const obj = {
          ...analyticsData.pageBodyInteraction.contactFormsClick,
          ...{
            label: createDynamicLabels([pageName, componentName, dynamicLabel]),
          },
        };
        pushAnaylyticsData(obj);
      }

      if (formType === COMMON_FORM) {
        data = {
          language,
          domain: canUseDOM && window.location.host,
          formData: {
            ...fieldName,
          },
        };
        formFields.fields.forEach(({ fields }) => {
          if (fields.fieldType.value === HIDDEN && get(fields, 'audienceId.value', '')) {
            data = {
              ...data,
              formData: {
                ...data.formData,
                audienceId: fields.audienceId.value,
              },
            }
          }
        });
        let thirdLevelData = [...get(fieldName, 'interests', [])];
        thirdLevelData.unshift(language);
        fieldName && fieldName.email && thirdLevelData.push(fieldName.email);
        thirdLevelData = thirdLevelData.join(' - ');
        const obj = {
          ...analyticsData.pageBodyInteraction.subscribeCompetition,
          ...{
            label: createDynamicLabels([pageName, componentName, thirdLevelData]),
          },
        };
        pushAnaylyticsData(obj);
        formSubmissionUrl = apiUrl.COMPETITION_FORM_API;
      }

      try {
        fetchService({
          url: formSubmissionUrl,
          method: 'POST',
          data: data,
        }).then(response => {
          if (response && response.status === 200) {
            setStatus(true);
          } else {
            setStatus(false);



          }
          setSubmissionStatus(true);
          if (response?.data?.detail && response?.data?.status == 'failed') {
            let popoverMessage = document.querySelector('#simple-popover .formSubmissionMessage.richText');
            popoverMessage.innerHTML = response.data.detail;
          }

        });
      } catch (err) {
        console.error('Contact Form API failure', err);
      }
    }
  };



  const renderField = ({ fields, index }) => {
    const { value: fieldType } = fields.fieldType;
    const error = errors[fields.fieldName && fields.fieldName.value];
    const invalidEmail = errors['invalidEmail'];
    let fieldProps = {
      ...fields,
      selectedValue: {
        value:
          fieldName[fields.fieldName && fields.fieldName.value] ||
          (fields.defaultValue && fields.defaultValue.fields && fields.defaultValue.fields.displayText.value),
      },
      error,
    };
    let otherProps = {};

    if (fieldType === MULTILINETEXT) {
      fieldProps = {
        ...fieldProps,
        multiline: true,
        rows: 3,
        rowsMax: 4,
      };
    }
    if (fieldType === EMAIL) {
      fieldProps = {
        ...fieldProps,
        invalidEmail: invalidEmail,
      };
    }
    if (fieldType === PHONE) {
      otherProps = {
        inputProps: {
          className: 'no-flip',
        },
      };
    }

    switch (fieldType) {
      case SINGLELINETEXT:
      case EMAIL:
      case PHONE:
      case MULTILINETEXT:
        return (
          <InputField
            key={index}
            data={fieldProps}
            onChange={e => handleChange(e, fieldType)}
            className={
              fields.fieldName.value === 'subject' ||
                fields.fieldName.value === 'message'
                ? 'fullWidth'
                : ''
            }
            other={otherProps}
          />
        );
      case DROPDOWN:
        return (
          <Select
            key={index}
            data={fieldProps}
            onChange={handleChange}
            className="selectMenu"
          />
        );
      case AUTOCOMPLETE:
        const hasSearchListItem = fieldProps && Array.isArray(fieldProps.listItems) && fieldProps.listItems.length > 0 && searchListItems.length === 0
        if (hasSearchListItem) {
          const filterRes = fieldProps['listItems'].map(o => ({ 'name': o.fields?.displayText?.value, 'val': o.fields?.key?.value }))
          setAutoCompleteListItems(filterRes)
          setSearchListItems(filterRes)
        }
        return (
          <AutoCompleteMUI
            key={index}
            data={fieldProps}
            disableClearable={true}
            inputChange={onInputChange}
            listItems={autoCompleteListItems}
            onChange={handleChange}
            // inputValue={selectedValue}
            // onKeyDown={onKeyDown}
            isSearchBar={true}
            // variant={'body3'}
            classNameInput={'autocomplete-field'}
          />
        );
      case CHECKBOX:
        return (
          <CheckBox
            key={index}
            data={fields}
            selected={fieldName[fields.fieldName && fields.fieldName.value]}
            onChange={e =>
              setFieldName({ ...fieldName, [e.target.name]: e.target.checked })
            }
          />
        );
      case CHECKBOXLIST: {
        const data = {
          ...fields,
          listItems: [
            ...fields.listItems.map(item => ({
              ...item,
              fields: {
                ...item.fields,
                selected: {
                  value:
                    fieldName[fields.fieldName && fields.fieldName.value] &&
                    fieldName[fields.fieldName.value].indexOf(
                      item.fields.key.value
                    ) > -1,
                },

                disabled: formType !== COMMON_FORM ? (
                  get(fields, 'defaultValue.fields.key.value') === (item.fields.key.value && fields.disableDefaultValue.value)
                ) : fields.defaultMultiValue &&
                fields.defaultMultiValue.some(defaultValue =>
                  (defaultValue.fields.key.value === item.fields.key.value) && fields.disableDefaultValue.value),
              },
            })),
          ],
        };
        return <CheckBox
          key={index}
          data={data}
          onChange={setMultipleCheckbox}
          className={!hasPopover && 'resetFieldset'}
        />;
      }
      case CAPTCHA: {
        if (canUseDOM) {
          window.recaptchaOptions = {
            useRecaptchaNet: true,
          };
        }
        return (
          fields.enableCaptcha.value &&
          captchaApiKey && (
            <div key={index} className={classes.captchaWrapper}>
              <ReCAPTCHA sitekey={captchaApiKey} onChange={recaptchaCallback} />
            </div>
          )
        );
      }
      case SUBMIT: {
        return (
          <ButtonMUI
            buttonType={'form'}
            type={'submit'}
            hasBorder={false}
            size="small"
            field={{ value: { text: fields.fieldLabel.value } }}
            disabled={!isFormValid}
          />
        );
      }
      default:
        return null;
    }
  };

  const { fields, formTitle, formDescription, tabText } = formFields;

  const submissionMessages = () => {
    const submitFields = fields.filter(
      field => field.fields.fieldType.value === SUBMIT
    );
    const failureMessage = get(
      submitFields[0],
      'fields.submitActions[0].fields.failureMessage',
      ''
    );
    const successMessage = get(
      submitFields[0],
      'fields.submitActions[0].fields.successMessage',
      ''
    );

    if (status) {
      return (
        <React.Fragment>
          <DctSvgIcon name="SuccessIcon" />
          <RichText
            innerHTML={successMessage}
            className="formSubmissionMessage"
          />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <DctSvgIcon name="FailureIcon" />
          <RichText
            innerHTML={failureMessage}
            className="formSubmissionMessage"
          />
          <ButtonMUI
            hasBorder={false}
            size="small"
            field={{ value: { text: getDictionaryText('backToForm') } }}
            onClick={() => setSubmissionStatus(false)}
            className="backToForm"
          />
        </React.Fragment>
      );
    }
  };

  const renderFormBuilder = (
    !submissionStatus ? (
      <div className={clsx(classes.root, className)}>
        {tabText && (
          <Title text={tabText} className="segment-title" />
        )}
        {formTitle && (
          <Typography
            variant="h4"
            component="h2"
            gutterBottom={false}
            className={classes.formTitle}
          >
            <Text field={formTitle} />
          </Typography>
        )}
        {formDescription && (
          <Typography
            variant="body1"
            component="p"
            gutterBottom={false}
            className={classes.formDescription}
          >
            <RichText innerHTML={formDescription} />
          </Typography>
        )}
        <form
          className={clsx(
            'form-wrapper',
            { focusOnFirstInput: hasPopover },
          )}
          onSubmit={handleSubmit}
          noValidate
          autoComplete="off"
        >
          {fields.map((field, index) => renderField(field, index))}
        </form>
      </div>
    ) : (
      <div className={clsx(classes.messageWrapper, 'messageWrapper')}>
        <div className={classes.submissionMessage}>
          {submissionMessages()}
        </div>
      </div>
    )
  )
  return (
    <React.Fragment>
      {
        hasPopover ? renderFormBuilder : (
          <Grid container>
            <Grid
              item
              xs={12}
              lg={7}
            >
              {renderFormBuilder}
            </Grid>
          </Grid>
        )
      }
    </React.Fragment>
  );
};

export default withErrorBoundary(
  withSitecoreContext()(withNamespaces()(FormBuild))
);
