import { makeStyles } from '@material-ui/core';
import { borderRadius } from '../../../../../styles/themes/vad/borderRadius';
import { pxToRem } from '../../../../../styles/themes/vad/constants';
import { fontSize } from '../../../../../styles/themes/vad/font';

const useStyles = makeStyles(theme => ({
  root: {
    '&.MuiTextField-root:not(.standard), & .MuiFormControl-root:not(.standard)': {
      marginBottom: pxToRem(24),
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        margin: theme.spacing(1),
        width: 'calc(50% - 16px)',
        marginBottom: pxToRem(32),
        marginTop: 0,

        '&.fullWidth': {
          width: 'calc(100% - 16px)',
        },
      },

      '& .MuiFormLabel-root': {
        [theme.breakpoints.up('lg')]: {
          lineHeight: fontSize.s32Rem,
        },
      },

      '& .MuiFormLabel-root, & .MuiTypography-root': {
        fontSize: fontSize.s14Rem,
        position: 'relative',
        transform: 'translate(0, -8px) scale(1)',
        ...theme.mixins.left(0),
        [theme.breakpoints.up('lg')]: {
          transform: 'translate(0, 0px) scale(1)',
        },

        '&.Mui-error': {
          color: 'inherit',
        },

        '& .MuiTypography-root': {
          transform: 'translate(0, -23px) scale(1)',
        },
      },

      '& .MuiFormHelperText-root': {
        ...theme.typography.body3,
        ...theme.mixins.marginLeft(0),
        marginTop: pxToRem(8),
        color: theme.mixins.Black(),
        '&.Mui-required': {
          marginTop: pxToRem(10),
          color: theme.mixins.AbuDhabiOrange(),
        },
      },

      '& .MuiInputBase-formControl': {
        border: theme.borders.fieldBorder,
        height: pxToRem(48),
        borderRadius: borderRadius.b0,
        marginTop: 0,
        '&.Mui-error': {
          borderColor: theme.palette.border.error,
        },
        '&:hover': {
          background: theme.mixins.Black(30),
          '&::placeholder': {
            color: theme.mixins.Black(),
          },
        },
        '&.MuiInputBase-multiline': {
          height: 'auto',
          padding: 0,
        },
        '& .MuiInputBase-inputMultiline': {
          height: pxToRem(96),
          padding: pxToRem(16),

          '&:before': {
            border: 0,
          },
        },
        '& input, & textarea': {
          padding: `0 ${pxToRem(16)}`,
          fontSize: fontSize.s16Rem,
          height: '100%',

          '&::placeholder': {
            color: theme.palette.text.tertiary,
          },
          '&::-webkit-input-placeholder': {
            /* Chrome/Opera/Safari */
            color: theme.palette.text.tertiary,
            opacity: 1,
          },
          '&::-moz-placeholder': {
            color: theme.palette.text.tertiary,
            opacity: 1,
          },
          '&:-ms-input-placeholder': {
            /* IE 10+ */
            color: theme.palette.text.tertiary,
            opacity: 1,
          },
          '&:-moz-placeholder': {
            /* Firefox 18- */
            color: theme.palette.text.tertiary,
            opacity: 1,
          },
        },
        '& fieldset': {
          border: 0,
        },
      },
    },

    '&.MuiTextField-root.standard, & .MuiFormControl-root.standard': {
      marginTop: '12px',
      marginBottom: '28px',
      width: '100%',

      '& input': {
        paddingBottom: '20px',
        fontSize: fontSize.s22Rem,
        lineHeight: fontSize.s32Rem,


        [theme.breakpoints.down('md')]: {
          padding: `0 ${pxToRem(8)}`,
          fontSize: fontSize.s14Rem,
          lineHeight: fontSize.s24Rem,
          height: '100%',
          borderBottom: 0,
        },
      },

      [theme.breakpoints.down('md')]: {
        marginTop: '0px',
        marginBottom: '0px',
        '& .MuiInputBase-formControl': {
          border: `1px solid ${theme.palette.common.DisabledGrey}`,
          height: pxToRem(40),
          borderRadius: borderRadius.b45Rem,
          marginTop: 0,
          padding: '8px 12px 8px 16px',

          '&:before': {
            border: 0,
          },
        },

        '& .MuiInput-underline': {
          '&:after': {
            border: 0,
          },
        },

      },
    },
  },
}));

export default useStyles;
