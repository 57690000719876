import React from 'react';
import Divider from '@material-ui/core/Divider';
import get from 'lodash/get';
import clsx from 'clsx';
import { createDynamicLabels } from '../../../../../../../src/utils/analytics';
import Box from '../../../../../components/molecules/Box';
import Typography from '../../../../../components/atoms/Typography';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { analyticsData } from '../../../../../../../src/constants/Analytics/analytics-data';
import Picture from '../../../../../components/atoms/Picture';
import Button from '../../../../../components/atoms/Button';
import Link from '../../../../../components/atoms/Link';

import useStyles from '../../style';

const FollowUs = ({
  isMobile,
  socialMediaHeading,
  socialMediaLinks,
  pageName,
  componentName,
}) => {
  const classes = useStyles();

  const getSocialIconInfo = (fieldItem, type, category) => {
    return get(fieldItem, `fields.${category}.value.${type}`, '');
  };

  return (
    <>
      <Box component="div" className={clsx(classes.newsletter, classes.pr0)}>
        <Typography
          component="h2"
          className={clsx(classes.heading, 'follow-us')}
          variant="h5Bold"
          animateSettings={{
            willAnimate: false,
          }}
        >
          {socialMediaHeading && <Text field={socialMediaHeading} />}
        </Typography>
        <Box component="div" className={classes.socialMediaIcons}>
          {socialMediaLinks &&
            socialMediaLinks.length > 0 &&
            socialMediaLinks.map((item, index) => {
              return (
                <Link
                  data-id={analyticsData.dataIds.footerNavIconclick}
                  data-link={createDynamicLabels([
                    pageName,
                    componentName,
                    `${analyticsData.dataLinksText.followus
                    } - ${getSocialIconInfo(item, 'text', 'link')}`,
                  ])}
                  key={index}
                  link={item.fields.link}
                  className={classes.followUsLinkItem}
                  aria-label={getSocialIconInfo(item, 'text', 'link')}
                >
                  {/* <Button iconOnly={true} component="span" tabIndex="-1"> */}
                  <Picture
                    media={{
                      image: item.fields.image,
                      renditionNeeded: false,
                      disableLazyLoad: true,
                      fallbackBackgroundNeeded: false,
                    }}
                    alternateAltText={getSocialIconInfo(item, 'text', 'link')}
                    isParalax={false}
                  />
                  {/* </Button> */}
                </Link>
              );
            })}
        </Box>
      </Box>
      {isMobile && (
        <Box component="div" className={classes.divider}>
          <Divider component="div" />
        </Box>
      )}
    </>
  );
};

export default FollowUs;
