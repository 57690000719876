import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withNamespaces } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import get from 'lodash/get';

import Popover from '../../../components/atoms/Popover';
import Typography from '../../../components/atoms/Typography';
import FormBuilder from '../../../components/organism/FormBuilder';
import { GOOGLE_CAPTCHA_API_KEY } from '../../../components/organism/FormBuilder/constants';
import withErrorBoundary from '../../../utils/HOCs/withErrorBoundary';
import { COMPONENT_NAME } from './locators';
import useStyles from './style';
import { getPageName, getComponentName } from '../../../utils/getPageComponentInfo';
import { pageContentType } from '../../../utils/getContentType';

const FormCollection = (props) => {
  const {
    fields,
    sitecoreContext,
    popoverPosition = 'top',
    hasBtn = false,
    formType,
    hasPopover = true,
  } = props;
  const pageName = getPageName(props);
  const componentName = getComponentName(props);
  const contentType = pageContentType(props).toLowerCase();
  const classes = useStyles({ contentType });
  if (!fields) {
    return null;
  }
  const platformSettings = get(
    sitecoreContext,
    'route.fields.platformSettings',
    []
  );

  const captchaKeyObj = platformSettings.filter(setting =>
    get(setting, 'fields.Key.value') === GOOGLE_CAPTCHA_API_KEY,
  )[0];

  const { headingText, formItemList, stripColor = {} } = fields;

  const renderFormBuilder = formField => formField.form && (
    <FormBuilder
      captchaApiKey={captchaKeyObj && get(captchaKeyObj, 'fields.Phrase.value', '')}
      formFields={formField.form.fields}
      requestType={get(
        formField,
        'requestType.fields.key.value',
        undefined
      )}
      componentName={componentName}
      pageName={pageName}
      emailSubject={get(
        formField,
        'requestType.fields.value.value',
        undefined
      )}
      language={sitecoreContext && sitecoreContext.language}
      formType={formType}
      hasPopover={hasPopover}
      className={!hasPopover && 'resetFormBuilderPosition'}
      stripColor={
        stripColor && stripColor.value
          ? stripColor
          : get(formField, 'form.fields.stripColor', {})
      }
    />
  );

  return (
    <div className={classes.root} data-locator={COMPONENT_NAME}>
      {headingText && (
        <Typography
          variant="h4"
          component="h2"
          className={classes.componentTitle}
        >
          <Text field={headingText} />
        </Typography>
      )}
      {hasPopover ? (
        formItemList && formItemList.map(({ fields: formField }, i) => (
          <Popover
            key={i}
            textField={{ value: formField.linkText.value }}
            position={popoverPosition}
            hasBtn={hasBtn}
            componentName={componentName}
            pageName={pageName}
            closeIconAriaLabel={`close ${get(formField, 'form.fields.formTitle.value', '')} form`}
          >
            {renderFormBuilder(formField)}
          </Popover>
        ))
      ) : (
          formItemList && formItemList.map(({ fields: formField }) => (
            renderFormBuilder(formField)
          ))
        )
      }
    </div>
  );
};

export default withErrorBoundary(
  withSitecoreContext()(withNamespaces()(FormCollection))
);
