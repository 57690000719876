import makeStyles from '@material-ui/core/styles/makeStyles';
import { pxToRem } from '../../../../styles/themes/vad/constants';
import get from 'lodash/get';

const useStyles = makeStyles(theme => ({
  root: {
    padding: pxToRem(24),
    top: pxToRem(80),
    border: 0,
    // adding this extra bottom padding to fix mobile browser native
    // bar overlapping
    backgroundColor: theme.palette.secondary.main,
    paddingBottom: pxToRem(80),
    borderColor: (stripColor, hasPopover) =>
      hasPopover && get(stripColor, 'fields.colorCode.value', theme.palette.common.Sunrise),
    [theme.breakpoints.down('md')]: {
      borderBottom: theme.borders.formCollectionMobile,
    },
    [theme.breakpoints.up('lg')]: {
      top: pxToRem(0),
      padding: pxToRem(42),
      ...theme.mixins.borderRight(theme.borders.formCollectionDesktop),
    },
    position: 'relative',
    '& .form-wrapper': {
      marginTop: pxToRem(24),
      '& .autocomplete-field': {
        '& .MuiFormControl-root': {
          margin: '0',
          width: '100%',
          '& .MuiInputBase-root': {
            marginTop: '2rem',
          },
          '& .MuiFormLabel-root': {
            top: '1.5rem',
            fontFamily: theme.custom.fontFamily.primaryLight,
          },
        },
        '& + .MuiAutocomplete-popper': {
          left: '0',
          top: '80px',
        },
      },

      '& .MuiFormControl-root': {
        marginBottom: pxToRem(24),
        width: '100%',
        [theme.breakpoints.up('lg')]: {
          margin: theme.spacing(1),
          width: 'calc(50% - 16px)',
          marginBottom: pxToRem(32),
          marginTop: 0,

          '&.fullWidth': {
            width: 'calc(100% - 16px)',
          },
        },

        '& .MuiFormLabel-root': {
          fontSize: 'inherit',
        },

        '&.selectMenu': {
          '& .MuiFormLabel-root': {
            transform: 'translate(0, -26px) scale(.9)',
            '& .MuiTypography-body2Bold': {
              fontFamily: theme.custom.fontFamily.primaryLight,
            },
          },
          '& .MuiInput-formControl': {
            marginTop: pxToRem(32),
            '& .MuiSelect-select': {
              fontFamily: theme.custom.fontFamily.primaryLight,
            },
          },
        },
      },
      '& button.Mui-disabled': {
        background: theme.mixins.Black(300),
      },
    },
  },
  '& .MuiPaper-root': {
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      maxHeight: '100%',
      top: 0,
      ...theme.mixins.left(0),
    },
  },
  captchaWrapper: {
    marginBottom: pxToRem(32),
  },
  messageWrapper: {
    position: 'relative',
    height: '100%',
    backgroundColor: theme.palette.secondary.main,
    borderColor: (stripColor, hasPopover) =>
      hasPopover && get(stripColor, 'fields.colorCode.value', theme.palette.common.Sunrise),
    [theme.breakpoints.down('md')]: {
      borderBottom: theme.borders.formCollectionMobile,
    },
    [theme.breakpoints.up('lg')]: {
      height: `calc(100vh - ${pxToRem(156)})`,
      ...theme.mixins.borderRight(theme.borders.formCollectionDesktop),
    },

    '& .formSubmissionMessage': {
      '& h2, & h3, & h4, & h5, & h6': {
        margin: 0,
        marginBottom: pxToRem(8),
      },
      '& p': {
        margin: 0,
      },
    },

    '& .backToForm': {
      color: theme.mixins.AbuDhabiOrange(),
      marginTop: pxToRem(24),
      '& .MuiButton-endIcon': {
        display: 'none',
      },
    },
  },
  submissionMessage: {
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '& svg': {
      marginBottom: theme.spacing(2),
    },
  },
  formTitle: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '88%',
    },
  },
  formDescription: {
    color: theme.palette.text.darkGrey,
    marginTop: pxToRem(16),
    marginBottom: pxToRem(32),
  },
}));

export default useStyles;
