const formFieldTypes = {
  SINGLELINETEXT: 'SingleLineText',
  EMAIL: 'Email',
  PHONE: 'Phone',
  MULTILINETEXT: 'MultiLineText',
  DROPDOWN: 'Dropdown',
  CHECKBOX: 'Checkbox',
  CHECKBOXLIST: 'CheckboxList',
  CAPTCHA: 'Captcha',
  SUBMIT: 'Submit',
  HIDDEN: 'Hidden',
  AUTOCOMPLETE: 'AutoComplete',
}

export const NEWSLETTER = 'newsletterForm';
export const COMMON_FORM = 'commonForm';

// this mapping is mentioned in confluence page
// https://tools.publicis.sapient.com/confluence/display/DVWMA/Feedback+Submission+API
export const requestTypeMapping = {
  Business_and_Meeting: 1,
  Complaint: 2,
  Compliment: 3,
  Enquiry: 4,
  Feedback: 5,
};

export const GOOGLE_CAPTCHA_API_KEY = 'googleCaptchaApiKey';

export default formFieldTypes;
