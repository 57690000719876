import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import get from 'lodash/get';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import Grid from '../../../../../components/molecules/Grid';
import Box from '../../../../../components/molecules/Box';
import Typography from '../../../../../components/atoms/Typography';
import IconWithCircle from '../../../../../components/atoms/IconWithCircle';
import Link from '../../../../../components/atoms/Link';
import { ScrollToTop } from '../../../../../../ScrollToTop';
import { analyticsData } from '../../../../../../../src/constants/Analytics/analytics-data';
import {
  pushAnaylyticsData,
  createDynamicLabels,
} from '../../../../../../utils/analytics';
import useStyles from '../../style';

const QuickLinks = ({
  isMobile,
  quickLinks,
  quickTitle,
  pageName,
  componentName,
}) => {
  const classes = useStyles();

  const getQuickLinkInfo = (fieldItem, type) => {
    return get(fieldItem, `fields.links.value.${type}`, '');
  };
  const scrollTopHandler = () => {
    addAnalyticsInfo();
    ScrollToTop('smooth');
  };
  const addAnalyticsInfo = () => {
    pushAnaylyticsData(analyticsData.pageBodyInteraction.backToTopClick);
  };

  const getQuickLinks = (totalColumns = 2) => {
    let quickLinksColumns = [[], [], []];
    let columnNumber = 0;
    quickLinks &&
      quickLinks.length > 0 &&
      quickLinks.forEach(item => {
        if (columnNumber > totalColumns - 1) {
          columnNumber = 0;
        }
        quickLinksColumns[columnNumber].push(item);
        columnNumber++;
      });
    return quickLinksColumns;
  };

  const getListItem = (item, index) => {
    return (
      <ListItem
        key={index}
        className={classes.listItem}
        disableGutters={true}
        dense={false}
      >
        <Link
          data-id={analyticsData.dataIds.footerNavLinkClick}
          data-link={createDynamicLabels([
            pageName,
            componentName,
            `${analyticsData.dataLinksText.quickLinks} - ${getQuickLinkInfo(
              item,
              'text'
            )}`,
          ])}
          // href={getQuickLinkInfo(item, 'href')}
          className={classes.linkItem}
          link={item.fields.links}
        >
          <Typography
            variant="body2Bold"
            className="quickLinkWithoutHover"
            component="span"
          >
            {getQuickLinkInfo(item, 'text')}
          </Typography>
        </Link>
      </ListItem>
    );
  };

  return (
    <>
      <Box component="div" className={classes.quickLinks}>
        <Grid container>
          <Typography
            component="h2"
            className={classes.heading}
            variant="h5Bold"
            animateSettings={{
              willAnimate: false,
            }}
          >
            {quickTitle && <Text field={quickTitle} />}
          </Typography>
          <Hidden lgUp implementation="css">
            <Box component="div" onClick={scrollTopHandler}>
              <IconWithCircle
                name="ArrowUpwardIcon"
                className={classes.scrollTopHover}
              />
            </Box>
          </Hidden>
          <Grid container>
            <Hidden
              lgUp
              implementation="css"
              className={classes.footerQuickLinks}
            >
              <Grid item xs={6}>
                <List>
                  {getQuickLinks(2)[0].map((item, index) => {
                    return getListItem(item, index);
                  })}
                </List>
              </Grid>

              <Grid item xs={6}>
                <Box pl={3}>
                  <List>
                    {getQuickLinks(2)[1].map((item, index) => {
                      return getListItem(item, index);
                    })}
                  </List>
                </Box>
              </Grid>
            </Hidden>

            <Grid container classes={{ root: 'quick-link-desktop-view' }}>
              <Grid item xs={4}>
                <List>
                  {getQuickLinks(3)[0].map((item, index) => {
                    return getListItem(item, index);
                  })}
                </List>
              </Grid>

              <Grid item xs={4}>
                <List>
                  {getQuickLinks(3)[1].map((item, index) => {
                    return getListItem(item, index);
                  })}
                </List>
              </Grid>

              <Grid item xs={4}>
                <List>
                  {getQuickLinks(3)[2].map((item, index) => {
                    return getListItem(item, index);
                  })}
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {isMobile && (
        <Box component="div" className={classes.divider}>
          <Divider component="div" />
        </Box>
      )}
    </>
  );
};

export default QuickLinks;
