import { makeStyles } from '@material-ui/core/styles';

import { pxToRem } from '../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => ({
  '@global': {
    '.hightlight-icon': {
      '& img': {
        width: 'inherit',
        maxWidth: '100%',
        objectFit: 'contain',
      },
      '& .MuiAvatar-root': {
        width: 'auto',
        height: 'auto',
      },
    },
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    transform: iconWithCircle => !iconWithCircle && 'translateX(-10px)',
  },
  textThenIcon: {
    '& .MuiAvatar-root': {
      ...theme.mixins.marginLeft(pxToRem(10)),
      background: iconWithCircle => !iconWithCircle && 'transparent',
    },
  },
  iconThenText: {
    '& .MuiAvatar-root': {
      ...theme.mixins.marginRight(pxToRem(10)),
      background: iconWithCircle => !iconWithCircle && 'transparent',
    },
  },
}));

export default useStyles;
