import React from 'react';
import TextField from '@material-ui/core/TextField';

import useStyles from './styles';

const TextFieldMUI = props => {
  const {
    fieldLabel,
    selectedValue,
    fieldName,
    placeholderText,
    isRequired,
    validationMessage,
    wrongEmailValidationMessage,
    isDisabled,
    error,
    invalidEmail,
    multiline = false,
    rows = null,
    rowsMax = null,
    type = "text",
    variant = "outlined",
    characterLimitMessage,
  } = props.data;

  const classes = useStyles();
  return (
    <>
      <TextField
        label={fieldLabel ? fieldLabel.value : ""}
        type={type}
        placeholder={placeholderText ? placeholderText.value : ''}
        value={selectedValue.value}
        name={fieldName.value}
        id={`input_${fieldName.value}`}
        onChange={props.onChange}
        InputLabelProps={{ shrink: true, htmlFor: `input_${fieldName.value}` }}
        variant={variant}
        error={error}
        helperText={(error && validationMessage && validationMessage.fields.displayText.value) || (invalidEmail && wrongEmailValidationMessage && wrongEmailValidationMessage.fields.displayText.value) || (characterLimitMessage && characterLimitMessage.value)}
        required={isRequired && isRequired.value}
        disabled={Boolean(isDisabled && isDisabled.value)}
        multiline={multiline}
        rows={rows}
        rowsMax={rowsMax}
        className={`${classes.root} ${props.className} ${variant}`}
        // this props.other is for material property
        // which is not currently used such as animation etc.
        {...props.other}
      />
      { }
    </>
  );
};

export default TextFieldMUI;
