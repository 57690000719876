// As we have some component variations based on the pageContentType, This is shared place to access the same
import get from 'lodash/get';
export const pageContentType = props => {
  const contentType = get(
    props,
    'sitecoreContext.route.fields.contentType.fields.taxonomyKey.value',
    ''
  );
  return contentType;
};
